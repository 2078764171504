import * as Msal from 'msal';

export interface IauthenticationService {
  getMsalApplication(): Promise<Msal.UserAgentApplication>;
  loginMsalRedirect(): Promise<any>;
  isAdminAccount(user: Msal.Account): boolean;
  getAccount(): Promise<Msal.Account>;
  getAccessToken(customScopes?: string[]): Promise<string>;
}
export const ExternalScopes = {
  graphScope: 'User.Read',
};

/**
 * Security Service takes care of the Authentication and authorization
 * concerns for Capella project. It uses MSAL API's to collect tokens
 * from Microsoft Azure Active Directory
 */
class AuthenticationService implements IauthenticationService {
  private _MsalApplication: Msal.UserAgentApplication = null;
  public async getMsalApplication(): Promise<Msal.UserAgentApplication> {
    if (this._MsalApplication == null) {
      const config = {
        auth: {
          clientId: 'b506118d-67ee-44d4-9bee-61bf30f7866d',
          authority:
            'https://login.microsoftonline.com/05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
          redirectUri: 'http://localhost:3000', // Make sure this matches the redirect URI in your app registration
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true,
        },
      };
      // const config = await globalService.getPublicConfig();

      //See: https://github.com/juunas11/7-deadly-sins-in-azure-ad-app-development/tree/master/MSALNoWildcardReplyURL
      const msal = new Msal.UserAgentApplication({
        auth: {
          authority: config.auth.authority,
          clientId: config.auth.clientId,
          redirectUri: window.location.origin + '/',
          postLogoutRedirectUri: window.location.origin + '/',
          navigateToLoginRequestUrl: false,
        },
        cache: { cacheLocation: 'localStorage' },
      });
      // msal.handleRedirectCallback((callback) => {
      //   console.error('MSAL error:', callback);
      // });
      // Handle the callback and remove token from URL
      msal.handleRedirectCallback((error, response) => {
        if (!error) {
          // Replace the state to remove tokens from URL
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        } else {
          console.error('MSAL error:', error);
        }
      });
      this._MsalApplication = msal;
    }
    return this._MsalApplication;
  }
  public loginMsalRedirect = async () => {
    const msal = await this.getMsalApplication();
    const scopes = await this.getCapellaScopes();
    scopes.push(ExternalScopes.graphScope);
    msal.loginRedirect({ scopes: scopes });
  };

  public isAdminAccount = (user: Msal.Account) => {
    const admRegEx = new RegExp(
      '^ADM[A-Za-z0-9]{6}\\@CRB\\.APMOLLER\\.NET$',
      'i'
    );
    return user ? admRegEx.test(user.idToken.preferred_username) : false;
  };

  public getAccount = async () => {
    const msal = await this.getMsalApplication();
    return msal.getAccount();
  };

  getIdToken = async () => {
    const msal = await this.getMsalApplication();
    return msal.getAccount()?.idToken;
  };

  logOut = async () => {
    const msal = await this.getMsalApplication();
    msal.logout();
  };

  public getAccessToken = async (customScopes?: string[]) => {
    const scopes = customScopes || (await this.getCapellaScopes());
    const msal = await this.getMsalApplication();
    if (msal.getAccount()) {
      const tokenRequest = {
        scopes: scopes,
      };

      try {
        const token = (await msal.acquireTokenSilent(tokenRequest)).accessToken;
        return token;
      } catch (err) {
        msal.acquireTokenRedirect(tokenRequest);
      }
    } else {
      // user is not logged in, you will need to log them in to acquire a token
      msal.loginRedirect({ scopes: scopes });
    }
  };

  private async getCapellaScopes() {
    return ['api://b506118d-67ee-44d4-9bee-61bf30f7866d/user_impersonation'];
  }
}

export let authenticationService = new AuthenticationService();
